import React from "react";
import "./style.scss";
import keralaLogo from "../../../Assets/Images/keralaLogo.png";
import QRCode from "react-qr-code";
import moment from "moment/moment";

export const PrintScreen = ({ bookingSummaryinfo }) => {
  if (bookingSummaryinfo === "{}") {
    // console.log("not work in print");
  }

  const headingStyle = {
    fontSize: "18px",
    fontStyle: "italic",
    fontFamily: "Times New Roman,arial",
    fontWeight: "bold",
  };
  const fnote = {
    fontSize: "15px",
    fontFamily: "italic",
    // paddingLeft: '50px',
  };
  const Juniorstyle = {
    float: "right",
    marginTop: "15px",
    fontSize: "15px",
    fontWeight: "bold",
  };
  return (
    <div className="bookingPrint" id="printablediv">
      <div className="headerSection">
        <div className="logoSection">
          <div className="logoImg">
            <img src={keralaLogo} alt="" />
          </div>
          <small>
            KERALA FORESTS AND WILDLIFE DEPARTMENT <br />
            {bookingSummaryinfo?.main_data?.pkg_name}
          </small>
        </div>
        <div className="qrSection">
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={bookingSummaryinfo?.main_data?._id.toString() || "null"}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
      <div className="bookingDetails">
        <p>
          {/* <span>No.</span>: ORAV/2022/00053 */}
          <span>No.:</span> {bookingSummaryinfo?.main_data?.booking_id}
        </p>

        {bookingSummaryinfo?.main_data?.type === "Trekking" ? (
          <p>
            <span>Booking Date:</span>
            {/* {bookingSummaryinfo?.main_data?.start_date} */}
            {moment(bookingSummaryinfo?.main_data?.createdAt).format(
              "DD-MM-YYYY"
            )}
          </p>
        ) : (
          <p>
            <span>Booking Date:</span>
            {/* {bookingSummaryinfo?.main_data?.start_date} -
            {bookingSummaryinfo?.main_data?.end_date} */}
            {moment(bookingSummaryinfo?.main_data?.createdAt).format(
              "DD-MM-YYYY"
            )}
          </p>
        )}
      </div>
      <div className="tableSection">
        <table className="table table-bordered">
          <thead>
            {bookingSummaryinfo?.main_data?.type === "Trekking" ? (
              <tr>
                <th scope="col">Sl.No.</th>
                <th scope="col">Name</th>
                <th scope="col">Age</th>
                <th scope="col">Gender</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Nationality</th>
                <th scope="col">Photo ID Type</th>
                <th scope="col">Photo ID No.</th>
                <th scope="col">Insurance No.</th>
                <th scope="col">Group Leader?</th>
              </tr>
            ) : (
              <tr>
                <th scope="col">Sl.No.</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Email</th>
                <th scope="col">Number of Adult</th>
                <th scope="col">Number of Child</th>
              </tr>
            )}
          </thead>
          <tbody>
            {bookingSummaryinfo?.main_data?.type === "Trekking" ? (
              bookingSummaryinfo?.details?.map((userDetails, index) => (
                <tr>
                  <th>{index + 1}</th>
                  <td>{userDetails.name}</td>
                  <td>{userDetails.age}</td>
                  <td>{userDetails.gender}</td>
                  <td>{userDetails.mobile}</td>
                  <td>{userDetails.user_category}</td>
                  <td>{userDetails.id_proof_type}</td>
                  <td>{userDetails.id_proof_number}</td>
                  <td>{userDetails.insurance_number}</td>
                  <td>{userDetails.is_group_leader ? "Yes" : "-"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <th>1</th>
                <td>{bookingSummaryinfo?.main_data?.user_name}</td>
                <td>{bookingSummaryinfo?.main_data?.phone}</td>
                <td>{bookingSummaryinfo?.main_data?.email}</td>
                <td>{bookingSummaryinfo?.main_data?.adult_count}</td>
                <td>{bookingSummaryinfo?.main_data?.child_count}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="bookingDatas">
        <div>
          {bookingSummaryinfo?.main_data?.type === "Stay" ? (
            <div className="row">
              <p>
                <span>Check-in Date</span>:{" "}
                {bookingSummaryinfo?.main_data?.start_date}
              </p>
              <p>
                <span>Check-out Date</span>:{" "}
                {bookingSummaryinfo?.main_data?.end_date}
              </p>
              <p>
                <span>Number of Rooms</span>:{" "}
                {bookingSummaryinfo?.main_data?.no_of_rooms}
              </p>
            </div>
          ) : (
            <p>
              <span>Date of journey</span>:{" "}
              {bookingSummaryinfo?.main_data?.start_date} <br /> (This entry
              pass is valid for
              <span className="bookingdatesView">
                {bookingSummaryinfo?.main_data?.start_date}
              </span>
              only.)
            </p>
          )}
          <p>
            <span>TOTAL AMOUNT Rs</span>:{" "}
            {bookingSummaryinfo?.main_data?.total_amount}
          </p>
        </div>
        <br></br>
        {bookingSummaryinfo?.main_data?.type !== "Stay" ? (
          <div className="reportSection">
            <p>
              {bookingSummaryinfo?.main_data?.type === "Ticket" ? (
                <span>Opening Time:</span>
              ) : (
                <span>Reporting Time:</span>
              )}{" "}
              {bookingSummaryinfo?.main_data?.reporting_time}
            </p>
            <p>
              <span>Reporting Place:</span>
              {bookingSummaryinfo?.main_data?.reporting_place}
            </p>
          </div>
        ) : (
          ""
        )}
        {bookingSummaryinfo?.main_data?.type === "Trekking" ? (
          <div>
            <div>
              <small>
                All Trekkers must display their original photo id before
                entering the Agastyaarkodam on the Trekking date (
                {bookingSummaryinfo?.main_data?.start_date})<br />
                Entry pass shall be valid only if the affidavit has been signed
                by the group leader/applicant.
              </small>
            </div>
            {/* <div>
              <small>
                Entry pass shall be valid only if the affidavit has been signed
                by the group leader/applicant.
              </small>
            </div> */}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="queries">
        <div>
          <h6>For Queries, please contact:</h6>
          <p>Wildlife Warden,</p>
          <p>PTP Nagar, Thiruvananthapuram</p>
          <p>Ph: 0471 2360762</p>
          <p>8547602954</p>
          <p>8547602948</p>
        </div>
        <div className="saveFRT">
          <h4>"SAVE FOREST..... SAVE WATER"</h4>
          <p>Facilitated by FMIS Wing</p>
        </div>
      </div>

      {bookingSummaryinfo?.main_data?.type === "Trekking" && (
        <div className="Conditions">
          <h6>
            <u>Conditions:-</u>
          </h6>
          <ol>
            <li>
              <small>
                Vegetarian food shall be provided to the trekkers at Athirumala
                Base Camp as part of the package. Details are given below.
              </small>
            </li>
            <br />
            <table class="table table-bordered">
              <thead>
                <th scope="col">Sl.No</th>
                <th scope="col">Time</th>
                <th scope="col">Food Item</th>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Day - 1</td>
                  <td>Evening(Tea/Coffee & Snacks), Dinner (Kanji)</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Day - 2</td>
                  <td>
                    Morning (Black Tea/ Coffee), Breakfast (Puttu/Puri + Curry),
                    Lunch(Meals), Evening(Black Tea/ Coffee & Snacks), Dinner
                    (Kanji)
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Day - 3</td>
                  <td>
                    Morning (Black Tea/ Coffee), Breakfast (Puttu/Puri + Curry)
                  </td>
                </tr>
              </tbody>
            </table>
            <li>
              <small>
                <b className="boldfont">
                  Since the programme involves trekking through dense forests
                  abundant in wildlife, undulated rugged terrain, hazardous and
                  slippy rock formations, trekkers need to bring trekking shoes
                  and minimum luggage. No poojas are allowed at the
                  Agasthyarkoodam. Intoxicants of any sort are strictly
                  prohibited.
                </b>
              </small>
            </li>
            <li>
              <small>
                <b>
                  Medical fitness certificate obtained within one week from the
                  date of Trekking from a registered Medical Practitioner of
                  Modern Medicine (Allopathy) not below the rank of an Asst.
                  Surgeon in the prescribed proforma should be produced at the
                  time of trekking; failing which permission will not be
                  granted.
                </b>
              </small>
            </li>
            <li>
              <small>
                All the trekkers have to compulsory submit the “Consent” form at
                Bonaccord Picket Station before commencing the trekking.
              </small>
            </li>
            <li>
              <small>
                On failing to turn up for the trek on the assigned date, the
                trekkers are not eligible for refund or swapping of individuals.
                However, in case of such an exigency, the trekkers can include
                new members by paying additional amount i.e. Rs.4,000/- per
                person.
              </small>
            </li>
            <li>
              <small>
                Do not litter pristine forests with non-biodegradable materials
                like plastic bags cups of bottles or any other materials which
                may pose danger to the wildlife.
              </small>
            </li>
            <li>
              <small>
                Extra care needs to be taken during the trek which involves
                crossing of rivers and streams. Kerala Forest Department will
                not be liable for accidents of any kind injury during the
                trekking programme.
              </small>
            </li>
            <li>
              <small>
                The original ID Cards should be produced for verification at the
                Bonaccord Picket Station on the first day of Trekking.
              </small>
            </li>
            <li>
              <small>
                <b>
                  The trekkers are strictly directed to use the trek paths alone
                  for trekking. Any trespass into sanctuary areas, capturing,
                  coursing, snaring, trapping, driving or bainting of any wild
                  animal, plucking leavers and flowers of plants and collection
                  of plants/plant parts is an offence and will attract penal
                  provisions as envisaged in Kerala Forest Act 1961 and Wildlife
                  Protection Act 1972.
                </b>
              </small>
            </li>
            <li>
              <small>
                Trekkers need to strictly follow the Do’s and Don’ts during the
                trek and violation of any kind will attract penal provisions as
                envisaged in by the Kerala Forest Act, 1961 and Wildlife
                Protection Act, 1972.
              </small>
            </li>
            <li>
              <small>
                No special provisions/ facilities will be available for women
                trekkers including the service of female ecotourism guides or
                forest personnel during the ‘special package’. Safety and
                protection of the women folk are the sole responsibility of
                their team members.
              </small>
            </li>
            <li>
              <small>
                Trekkers need to report at Forest Check post Kanithadam enroute
                to Bonaccord before 9 am on the date assigned.
              </small>
            </li>
            <li>
              <small>
                Duration of the Trekking is three days and the trekkers should
                scrupulously follow the instructions of ecotourism guides/
                forest personnel during the trekking programme.
              </small>
            </li>
            <li>
              <small>
                Kerala Forest Department is not liable nor will refund the money
                paid if the trekkers fail to complete the trek due to inclement
                weather or natural vagaries.
              </small>
            </li>
            <li>
              <small>
                Wildlife Warden, Thiruvananthapuram is empowered to defer or
                cancel any permission given for trekking to Agasthyarkoodam or
                modify conditions laid out without giving prior intimation /
                reasons and his decision would be final.
              </small>
            </li>
            <li>
              <small>
                <b>
                  Trekking shall be allowed depending upon the climatic
                  conditions.
                </b>
              </small>
            </li>
            <li>
              <small>
                Playing music during trekking is strictly prohibited.
              </small>
            </li>

            <li>
              <small>
                Each group of Trekkers will be accompanied by
                <b>
                  trained guides and the trekkers shall obey the directions of
                  guides.
                </b>
                .
              </small>
            </li>
            <li>
              <small>
                For details/queries, if any, trekkers may seek assistance from
                <b>8547602954, 8547602948 or 0471-2360762.</b>
              </small>
            </li>
          </ol>

          <div className="queries">
            {/* <div>
              <h5 style={headingStyle}>
                <b>Approved For issue</b>
              </h5>
              <br />

              <p style={Juniorstyle}>
                Junior Superintendent
                <br />
                <br />
              </p>
            </div> */}

            <div className="saveFRT">
              <small>
                Sd/-
                <br /> Wildlife Warden,
                <br /> PTP Nagar,Thiruvananthapuram.
              </small>
              <h4>"SAVE FOREST..... SAVE WATER"</h4>
            </div>
          </div>
          <small style={fnote}>
            Copy to Asst. Wildlife Warden, Peppara for information and necessary
            action.
          </small>
        </div>
      )}
      {bookingSummaryinfo?.main_data?.type === "Trekking" && (
        <div className="affidavitConatiner">
          <div className="text-center p-3">
            <h1>Affidavit</h1>
          </div>
          <div className="affidavitContent">
            <p>
              I ________________________________S/o ___________________________
              residing at ______________________________________ undertake as
              follows:
            </p>
            <ol>
              <li>
                I and my team members will abide by the rules and regulations of
                Kerala Forests and Wildlife Department and all instructions
                issued by the Forest staff and guides.
              </li>
              <li>
                Kerala Forests and Wildlife Department can initiate action
                against us, in case of violation of any instructions during the
                trekking programme.
              </li>
              <li>
                I/We are fully aware of the risk involved in the trekking
                programme including attacks from wild animals and health
                problems which may develop during trekking.
              </li>
              <li>
                I/We am/are undertaking trekking programme on our own
                responsibility and Kerala Forests and Wildlife Department and
                Eco Development Committee will not be held responsible for any
                loss/damage to life or property of any of us.
              </li>
              <li>
                I/We do swear that no plastic carry bags/bottles/utensils/food
                stuffs will be carried to the forest during the trekking and
                that no cooking/ Consumption of Liquor will be attempted. Dated
                this the day of January/February 20
              </li>
            </ol>
          </div>

          <div className="affidavitSign">
            <div>
              <p>Place:</p>
              <p>Forest Picket Station, Bonaccord</p>
            </div>
            <div>
              <p>Sign:</p>
              <p>Name of Team Leader: </p>
            </div>
          </div>
          <div className="affidavitfooter">
            <h6>For Queries, please contact:</h6>
            <p>Wildlife Warden,</p>
            <p>PTP Nagar, Thiruvananthapuram</p>
            <p>Ph: 0471-2360762</p>
          </div>
        </div>
      )}

      {bookingSummaryinfo?.main_data?.type === "Trekking" && (
        <div class="containeri">
          <h1 className="mt-5">
            <u>MEDICAL CERTIFICATE OF FITNESS</u>
          </h1>
          <p>
            I have examined Shri/ Kumari/
            Smt.………………………………………………………………………………………………………………………………. Son/ Daughter
            of Shri …………………………………………………………………………………………… aged…………. Years, of
            Village ………………………………………………………………… P. O…………………… …………………………… P.S
            ………………………Dist…………………………………. State……………………………………………… PIN …………………………
            and certify that, he/ she is free from deafness, defective vision
            (including colour vision) or any other infirmity, mental or
            physical, likely to interfere with the efficiency of his/ her work
            and found him/ her possessing good health.
          </p>
          <p>
            <br />
            I/ We also certify that he/ she has marks of small pox/ Vaccination.
          </p>

          <ul>
            <li>
              <span className="dot"></span>
              <div className="signature-details">
                Chest measurement in
                <span class="details">
                  On full Expiration
                  <br />
                  On full inspiration
                  <br />
                  Difference (expansion)
                </span>
              </div>
            </li>
            <li>
              <span className="dot"></span>Weight in kg.
            </li>
            <li>
              <span className="dot"></span>Cardio – Vascular system.
            </li>
            <li>
              <span className="dot"></span>Respiratory System.
            </li>
            <li>
              <span className="dot"></span>His/ Her vision is normal.
            </li>
            <li>
              <span className="dot"></span>
              <li class="hypermetropic-mypopic">Hypermetropic/ Mypopic.</li>
              <li class="astigmatic-margin">Astigmatic</li>
            </li>
            <li>
              (here enter the degree of defect and the strength of correction
              glasses)
            </li>
            <br />
            <br />
          </ul>
          <p className="bold-text">
            <br />
            This certificate is being given to him/ her for the purpose of
            Trekking for 3 days to Agasthyarkoodam.
          </p>
          <div class="signature">
            <p>
              Signature of Candidate
              <br /> (To be signed in presence of the Medical Officer)
            </p>
            <p>Signature of Medical Officer: ……………………………………………</p>
            <p>Name of Medical Officer: Dr. ………………………………………………</p>
            <p>Registration No: ……………………………………………………………………………</p>
            <p>Rank: ………………………………………………………………………………………………………</p>
          </div>
          <p>
            <span class="dated"> Dated: </span>
            <span class="astigmatic">Seal</span>
          </p>
        </div>
      )}
      {bookingSummaryinfo?.main_data?.type === "Trekking" && (
        <div className="declaration">
          <h3>
            <b>
              Kerala Forest & Wildlife Department Neyyar - Peppara Forest
              Development Agency
            </b>
          </h3>
          <h2>DECLARATION</h2>
          <h2>
            (Declaration given for participating in the programme conducted by
            FDA in association with Kerala Forest Department)
          </h2>
          <p>
            I do hereby declare that I am fully aware of the details of this
            programme and of all possible hazards of walking in to the forest
            of. Thiruvananthapuram Wildlife Division
          </p>
          <p>
            I also declare that I am confident of my health condition to
            undertake the walk of tedious nature and nobody else will be
            responsible for any injuries or causalities. I am not entitled to
            claim any compensation from the EDC or from the Kerala Forest
            Department, for any damages to my personal health and personal
            belongings while participating in the programme (Agasthyarkoodam
            Trekking) of Bonaccord EDC
          </p>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>SL No</th>
                <th>Name & Address</th>
                <th>ID Number</th>
                <th>M/F</th>
                <th>Age</th>
                <th>Phone Number</th>
                <th>Signature</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>5</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>6</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>7</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>8</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>9</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>10</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
