import React, { useEffect, useRef, useState } from 'react'
import banner from '../../../Assets/Images/inner-banner/inner-banner.jpg'
import { BaseUrl } from '../../../Services/baseUrl'
import { orderPayment } from './orderPayment'
import { Navigate, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { PrintScreen } from '../printScreen/PrintScreen'
import { useReactToPrint } from 'react-to-print'
import Loading from '../../Layouts/Loading'
import { getBookingSummarySerivce } from '../../../Services/BaseApiPostService'
import { userLogout } from '../../Layouts/UserService'
import { scrollToTop } from '../../../Services/CommonService'

const BookingSummary = ({ bookingSummaryId, getactivestaydata }) => {
  const [bookingSummaryinfo, setbookingSummaryinfo] = useState({})
  const [isPaid, setisPaid] = useState(false)
  const [islodaing, setislodaing] = useState(false)
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'TWD',
  })

  // const subPackage = getactivesubpackagesdata?.filter(
  //   (sub) => sub.pkg_id === id
  // )
  const params = useParams()
  const { id } = params
  const activeStayId = getactivestaydata?.filter(
    (Stay) => Stay._id === bookingSummaryinfo?.main_data?.pkg_id
  )

  let navigate = useNavigate()
  const getBookingSummary = async () => {
    // const dataForm = {
    //   id: bookingSummaryId,
    // }

    let dataForm = {
      id: bookingSummaryId,
    }

    if (sessionStorage.getItem('bookingSummaryId')) {
      const bdata = JSON.parse(sessionStorage.getItem('bookingSummaryId'))
      dataForm = {
        id: bdata,
      }
    }

    const res = await getBookingSummarySerivce(dataForm)

    if (res.data.status) {
      setbookingSummaryinfo(res.data.data)
    } else {
      if (res.data.message === 'Invalid token') {
        userLogout()
      }
    }
  }
  useEffect(() => {
    getBookingSummary()
  }, [id, isPaid])

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <>
      {bookingSummaryinfo?.details ? (
        islodaing ? (
          <Loading />
        ) : (
          <div className='bookingConatiner'>
            {/* <PrintScreen bookingSummaryinfo={bookingSummaryinfo} /> */}
            <div className='bannerConatiner'>
              <img src={banner} alt='pic' />
              <div className='bannerText'>
                <h3>
                  {bookingSummaryinfo?.main_data?.booking_status === 'pending'
                    ? 'Booking Summary'
                    : '  Booking Confirmation'}
                </h3>
              </div>
            </div>
            <div className='bookingSections bookingSummaryConatiner'>
              {bookingSummaryinfo.main_data.type === 'Ticket' ? (
                <div className='summarymainConatiner'>
                  <div className='bannerSectionBS'>
                    <p>Please confirm your booking details</p>
                  </div>
                  <div className='bstableConatiner'>
                    <table class='table'>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Email</th>
                          <th>Nationality</th>
                          <th>Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <td>{bookingSummaryinfo.main_data.user_name}</td>
                        <td>{bookingSummaryinfo.main_data.date}</td>
                        <td>{bookingSummaryinfo.main_data.email}</td>
                        <td>{bookingSummaryinfo.main_data.user_category}</td>
                        <td>{bookingSummaryinfo.main_data.phone}</td>
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      border: '1px solid #ccc',
                      padding: '10px',
                      borderRadius: '5px',
                      marginBottom: '15px',
                    }}
                  >
                    <p style={{ marginBottom: '8px' }}>
                      No of Adults: {bookingSummaryinfo.main_data.adult_count}
                    </p>
                    <p style={{ marginBottom: '8px' }}>
                      No of Children(0-5):{' '}
                      {bookingSummaryinfo.main_data.child_count}
                    </p>
                    <p style={{ marginBottom: '8px' }}>
                      No of Children(5-13):{' '}
                      {bookingSummaryinfo.main_data.child_count_range}
                    </p>
                  </div>

                  <div className='bstotalConatiner'>
                    <p>Total Amount</p>
                    <p>Rs. {bookingSummaryinfo?.main_data?.total_amount}.00</p>
                  </div>
                  <div className='payConatinerSection'>
                    {/* {bookingSummaryinfo?.main_data?.booking_status ===
                    "pending" ? (
                      <>
                        <button
                          className="mainButton"
                          onClick={() =>
                            navigate(`/paymentGateway/${bookingSummaryId}`)
                          }
                        >
                          Pay Now
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="mainButton printBtn"
                          onClick={handlePrint}
                        >
                          Print Ticket
                        </button>
                      </>
                    )} */}
                    {bookingSummaryinfo?.main_data?.is_approval_required ? (
                      <>
                        <Navigate to='/myBookings' />
                      </>
                    ) : (
                      <>
                        <button
                          className='mainButton'
                          onClick={() =>
                            navigate(`/paymentGateway/${bookingSummaryId}`)
                          }
                        >
                          Pay Now
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ) : bookingSummaryinfo.main_data.type === 'Stay' ? (
                <div className='summarymainConatiner'>
                  <div className='bannerSectionBS'>
                    <p>Please confirm your booking details</p>
                  </div>
                  <div className='bstableConatiner'>
                    <table class='table'>
                      <thead>
                        <tr>
                          <th>Sl.No.</th>
                          <th>Name</th>
                          {/* <th>Age</th>
                          <th>Gender</th> */}
                          <th>Mobile Number</th>
                          <th>Nationality</th>
                          <th>Proof Type</th>
                          <th>Proof No.</th>
                          <th>Insurance No.</th>
                          <th>Adults</th>
                          <th>Children</th>
                          <th>Rooms</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingSummaryinfo?.details?.map(
                          (userDetails, index) => (
                            <tr key={userDetails._id}>
                              <th>{index + 1}</th>
                              <td>{userDetails.name}</td>
                              {/* <td>{userDetails.age}</td>
                              <td>{userDetails.gender}</td> */}
                              <td>{userDetails.mobile}</td>
                              <td>{userDetails.user_category}</td>
                              <td>{userDetails.id_proof_type}</td>
                              <td>{userDetails.id_proof_number}</td>
                              <td>{userDetails.insurance_number}</td>
                              <td>
                                {bookingSummaryinfo.main_data.adult_count}
                              </td>
                              <td>
                                {bookingSummaryinfo.main_data.child_count}
                              </td>
                              <td>
                                {bookingSummaryinfo.main_data.no_of_rooms}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='bstotalConatiner'>
                    <p>Total Amount</p>
                    <p>Rs. {bookingSummaryinfo?.main_data?.total_amount}.00</p>
                  </div>
                  <div className='payConatinerSection'>
                    {/* {bookingSummaryinfo?.main_data?.booking_status ===
                    "pending" ? (
                      <>
                        <button
                          className="mainButton"
                          onClick={() =>
                            navigate(`/paymentGateway/${bookingSummaryId}`)
                          }
                        >
                          Pay Now
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="mainButton printBtn"
                          onClick={handlePrint}
                        >
                          Print Ticket
                        </button>
                      </>
                    )} */}
                    {bookingSummaryinfo?.main_data?.is_approval_required ? (
                      <>
                        <Navigate to='/myBookings' />
                      </>
                    ) : (
                      <>
                        <button
                          className='mainButton'
                          onClick={() =>
                            navigate(`/paymentGateway/${bookingSummaryId}`)
                          }
                        >
                          Pay Now
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className='summarymainConatiner'>
                  <div className='bannerSectionBS'>
                    <p>Please confirm your booking details</p>
                  </div>
                  <div className='bstableConatiner'>
                    <table class='table'>
                      <thead>
                        <tr>
                          <th>Sl.No.</th>
                          <th>Name</th>
                          <th>Age</th>
                          <th>Gender</th>
                          <th>Mobile Number</th>
                          <th>Nationality</th>
                          <th>Proof Type</th>
                          <th>Proof No.</th>
                          <th>Insurance No.</th>
                          <th>Group Lead</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingSummaryinfo?.details?.map(
                          (userDetails, index) => (
                            <tr key={userDetails._id}>
                              <th>{index + 1}</th>
                              <td>{userDetails.name}</td>
                              <td>{userDetails.age}</td>
                              <td>{userDetails.gender}</td>
                              <td>{userDetails.mobile}</td>
                              <td>{userDetails.user_category}</td>
                              <td>{userDetails.id_proof_type}</td>
                              <td>{userDetails.id_proof_number}</td>
                              <td>{userDetails.insurance_number}</td>
                              <td>
                                {userDetails.is_group_leader ? 'Yes' : '-'}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='bstotalConatiner'>
                    <p>Total Amount</p>
                    <p>Rs. {bookingSummaryinfo?.main_data?.total_amount}.00</p>
                  </div>
                  <div className='payConatinerSection'>
                    {/* {bookingSummaryinfo?.main_data?.booking_status ===
                    "pending" ? (
                      <>
                        <button
                          className="mainButton"
                          onClick={() =>
                            navigate(`/paymentGateway/${bookingSummaryId}`)
                          }
                        >
                          Pay Now
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="mainButton printBtn"
                          onClick={handlePrint}
                        >
                          Print Ticket
                        </button>
                      </>
                    )} */}
                    {bookingSummaryinfo?.main_data?.is_approval_required ? (
                      <>
                        <Navigate to='/myBookings' />
                      </>
                    ) : (
                      <>
                        <button
                          className='mainButton'
                          onClick={() =>
                            navigate(`/paymentGateway/${bookingSummaryId}`)
                          }
                        >
                          Pay Now
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}

              <div className='summarysubConatiner'>
                <div className='bsimgConatiner'>
                  <img
                    src={`${BaseUrl}/${bookingSummaryinfo?.main_data?.package_image}`}
                    alt='pic'
                  />
                </div>
                <div className='bsPackageDetails'>
                  <div className='PackageDetailsHead'>
                    <p>Package Details</p>
                  </div>
                  <div className='PackageDetailsSection'>
                    <p>{bookingSummaryinfo?.main_data?.pkg_name}</p>
                  </div>
                </div>
                <div className='bsPackageDetailsofper'>
                  {bookingSummaryinfo?.main_data?.type === 'Ticket' ? (
                    <>
                      <p>
                        Opening Time:
                        <span>
                          {bookingSummaryinfo?.main_data?.reporting_time}
                        </span>
                      </p>
                      <p>
                        Closing Time:
                        <span>
                          {bookingSummaryinfo?.subpkgdetails?.close_time}
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      {/* <p>
                    Reporting Time:
                    <span>{bookingSummaryinfo?.main_data?.reporting_time}</span>
                  </p>
                  <p>
                    Reporting place:
                    <span>
                      {bookingSummaryinfo?.main_data?.reporting_place}
                    </span>
                  </p> */}
                    </>
                  )}
                  {bookingSummaryinfo?.main_data?.type === 'Stay' ? (
                    <>
                      <p>
                        Checkin time:
                        <span>{activeStayId[0]?.check_in}</span>
                      </p>
                      <p>
                        Checkout time:
                        <span>{activeStayId[0]?.check_out}</span>
                      </p>
                    </>
                  ) : (
                    ''
                  )}
                  {bookingSummaryinfo?.main_data?.type === 'Trekking' ? (
                    <>
                      <p>
                        Reporting Time:
                        <span>
                          {bookingSummaryinfo?.main_data?.reporting_time}
                        </span>
                      </p>
                      <p>
                        Reporting place:
                        <span>
                          {bookingSummaryinfo?.main_data?.reporting_place}
                        </span>
                      </p>{' '}
                    </>
                  ) : (
                    ''
                  )}
                  {bookingSummaryinfo?.main_data?.type === 'Trekking' ||
                  bookingSummaryinfo?.main_data?.type === 'Ticket' ? (
                    <>
                      <p
                        style={{
                          fontWeight: 600,
                          marginTop: '15px',
                          marginBottom: '5px',
                        }}
                      >
                        Booking date
                      </p>
                      <p>{bookingSummaryinfo?.main_data?.start_date}</p>
                    </>
                  ) : (
                    <>
                      <p
                        style={{
                          fontWeight: 600,
                          marginTop: '15px',
                          marginBottom: '5px',
                        }}
                      >
                        Booking dates:
                      </p>
                      <p>
                        {bookingSummaryinfo?.main_data?.start_date}
                        {''} to {''}
                        {bookingSummaryinfo?.main_data?.end_date}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div style={{ display: 'none' }}>
              <div ref={componentRef} style={{ width: '100%' }}>
                <PrintScreen bookingSummaryinfo={bookingSummaryinfo} />
              </div>
            </div>
          </div>
        )
      ) : (
        <Loading />
      )}
    </>
  )
}

export default BookingSummary
