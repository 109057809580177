import React, { useState } from "react";
import "./bookings.scss";
import banner from "../../../Assets/Images/inner-banner/inner-banner.jpg";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useParams } from "react-router-dom";
import Bookignform from "./Bookignform";
import { useNavigate } from "react-router-dom";
import createDOMPurify from "dompurify";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  checkPackageAvailabilitySerivce,
  getAvailableSlotSerivce,
} from "../../../Services/BaseApiPostService";
import { userLogout } from "../../Layouts/UserService";
import {
  convertDateToFormattedString,
  dateconvert,
  scrollToTop,
} from "../../../Services/CommonService";
import moment from "moment";

const Booking = ({
  getactivesubpackagesdata,
  setbookingSummaryId,
  loginPopup,
  setloginPopup,
}) => {
  const params = useParams();
  const { id } = params;
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentDate, setcurrentDate] = useState(new Date());
  const [isEvent, setIsEvent] = useState(false);
  const [formPopup, setformPopup] = useState(false);
  const [formPopupLoading, setformPopupLoading] = useState(false);
  const [getAvailableSlotdata, setgetAvailableSlot] = useState();
  const [dateDeatils, setdateDeatils] = useState();
  const [realstart, setRealstart] = useState();
  const [display, setDisplay] = useState(false);

  const DOMPurify = createDOMPurify(window);
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const convertcal = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  useEffect(() => {
    setCurrentTime(new Date());
    return () => {
      // No need for setInterval, just clearing the interval
    };
  }, []);
  const formattedTime = currentTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const date = new Date();
  const subpckgId = getactivesubpackagesdata?.filter(
    (sbpck) => sbpck._id === id
  );

  useEffect(() => {
    navigate(`/booking/${id}`);
  }, [loginPopup]);

  useEffect(() => {
    scrollToTop();
  }, []);

  // Extract the date, month, and year
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
  const year = currentDate.getFullYear();

  // Format the date
  const formattedDate = `${day}-${month < 10 ? "0" + month : month}-${year}`;
  const checkPackageAvailability = async (data) => {
    setformPopupLoading(true);
    const dataForm = {
      sub_pkg_id: subpckgId[0]?._id,
      date: dateconvert(data?.event._instance.range.start),
    };

    const res = await checkPackageAvailabilitySerivce(dataForm);
    if (res.data.status) {
      // toast.success(res.data.message);
      setdateDeatils(dateconvert(data?.event._instance.range.start));
      sessionStorage.setItem(
        "dateDeatils",
        JSON.stringify(dateconvert(data?.event._instance.range.start))
      );
      setformPopupLoading(false);
      getAvailableSlot(data?.event._instance.range.start);
    } else {
      toast.error(res.data.message);
      setformPopupLoading(false);
    }
  };

  // const BookingDateSubmit = (data) => {
  //   // const eventDate = dateconvert(data?.event._instance.range.start);
  //   const eventDate = moment(data?.event._instance.range.start).format("YYYY-MM-DD") + " 00:00:00";
  //   const startTime = "00:00:00";

  //   const reportingTime = subpckgId[0]?.reporting_time;
  //   const formattedCurrentDate = moment(currentDate).format("DD-MM-YYYY");
  //   console.log(formattedCurrentDate);
  //   const formattedCurrentTime = moment(currentDate).format("HH:mm:ss");
  //   console.log(formattedCurrentTime);
  //   console.log("eventdate", eventDate);
  //   console.log("reporting time", reportingTime);

  //   if (!userInfo) {
  //     setloginPopup(true);
  //   } else if (formattedDate === eventDate) {
  //     if (reportingTime) {
  //       // Convert time strings to Date objects
  //       const dateFormattedTime = new Date("2000-01-01 " + formattedTime);
  //       const dateReportingTime = new Date("2000-01-01 " + reportingTime);

  //       // Get the time in minutes since midnight
  //       const minutesFormattedTime =
  //         dateFormattedTime.getHours() * 60 + dateFormattedTime.getMinutes();
  //       const minutesReportingTime =
  //         dateReportingTime.getHours() * 60 + dateReportingTime.getMinutes();

  //       if (minutesFormattedTime > minutesReportingTime) {
  //         toast.error("Booking not allowed after reporting time");
  //       }
  //     }
  //   } else {
  //     checkPackageAvailability(data);
  //     scrollToTop();
  //   }
  // };

  const BookingDateSubmit = (data) => {
    const eventDate =
      moment(data?.event._instance.range.start).format("YYYY-MM-DD") +
      " 00:00:00";

    const reportingTime = subpckgId[0]?.reporting_time;

    const currentDate = new Date();
    const formattedCurrentDate = moment(currentDate).format("YYYY-MM-DD");
    const formattedCurrentTime = moment(currentDate).format("HH:mm:ss");

    if (!userInfo) {
      setloginPopup(true);
      return;
    }

    // Calculate the difference between the current time and the event start time
    const currentDateTime = new Date(
      formattedCurrentDate + " " + formattedCurrentTime
    );
    const eventDateTime = new Date(eventDate);

    // Calculate the difference in hours between the current time and the event time
    const timeDifferenceInMinutes =
      (eventDateTime - currentDateTime) / (1000 * 60); // Time difference in minutes

    // If the time difference is less than 7 hours (420 minutes), show an error message
    if (timeDifferenceInMinutes <= 420) {
      toast.error("Event booking not allowed. Time expired");
      return;
    }

    if (formattedCurrentDate === eventDate.split(" ")[0]) {
      if (reportingTime) {
        // Convert the formattedTime and reportingTime to Date objects
        const dateFormattedTime = new Date(
          formattedCurrentDate + " " + formattedCurrentTime
        );
        const dateReportingTime = new Date(
          formattedCurrentDate + " " + reportingTime
        );

        // Get the time in minutes since midnight for comparison
        const minutesFormattedTime =
          dateFormattedTime.getHours() * 60 + dateFormattedTime.getMinutes();
        const minutesReportingTime =
          dateReportingTime.getHours() * 60 + dateReportingTime.getMinutes();

        if (minutesFormattedTime > minutesReportingTime) {
          toast.error("Booking not allowed after reporting time");
          return;
        }
      }
    } else {
      // Proceed to check package availability and other logic
      checkPackageAvailability(data);
      scrollToTop();
    }
  };

  const popUpSet = (value) => {
    setgetAvailableSlot(value);
    setformPopup(true);
    setformPopupLoading(false);
  };
  const getAvailableSlot = async (selectedDate) => {
    setgetAvailableSlot("Loading...");
    setformPopupLoading(true);
    const dataForm = {
      sub_pkg_id: id,
      date: dateconvert(selectedDate),
    };

    const res = await getAvailableSlotSerivce(dataForm);
    if (res.data.status) {
      if (res.data.data.slots <= 0) {
        setformPopupLoading(false);
        toast.error("No slot avaliable for this date, try another date");
      } else {
        setformPopupLoading(false);
        popUpSet(res.data.data);
      }
    } else {
      setformPopupLoading(false);
      toast.error(res.data.message);
      if (res?.data.message === "Invalid token") {
        userLogout();
      }
    }
  };

  // const start_date = getactivesubpackagesdata
  //   ?.filter((subpck) => subpck._id === id)
  //   .map((subpackages) => subpackages.start_date);
  // const end_date = getactivesubpackagesdata
  //   ?.filter((subpck) => subpck._id === id)
  //   .map((subpackages) => subpackages.start_date);
  const monday = getactivesubpackagesdata
    ?.filter((subpck) => subpck._id === id)
    .map((subpackages) => subpackages.monday);
  const tuesday = getactivesubpackagesdata
    ?.filter((subpck) => subpck._id === id)
    .map((subpackages) => subpackages.tuesday);
  const wednesday = getactivesubpackagesdata
    ?.filter((subpck) => subpck._id === id)
    .map((subpackages) => subpackages.wednesday);
  const thursday = getactivesubpackagesdata
    ?.filter((subpck) => subpck._id === id)
    .map((subpackages) => subpackages.thursday);
  const friday = getactivesubpackagesdata
    ?.filter((subpck) => subpck._id === id)
    .map((subpackages) => subpackages.friday);
  const saturday = getactivesubpackagesdata
    ?.filter((subpck) => subpck._id === id)
    .map((subpackages) => subpackages.saturday);
  const sunday = getactivesubpackagesdata
    ?.filter((subpck) => subpck._id === id)
    .map((subpackages) => subpackages.sunday);

  const endDate = getactivesubpackagesdata
    ?.filter((subpck) => subpck._id === id)
    .map((subpackages) => subpackages.end_date);
  const startDate = getactivesubpackagesdata
    ?.filter((subpck) => subpck._id === id)
    .map((subpackages) => subpackages.start_date);

  // Calculate the new start date as today + 3 days
  const today = new Date();
  today.setDate(today.getDate() + 3);

  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const startYear = firstDayOfMonth.getFullYear();
  const startMonth = String(firstDayOfMonth.getMonth() + 1).padStart(2, "0");
  const startDay = String(firstDayOfMonth.getDate()).padStart(2, "0");

  const startDateISO = `${startYear}-${startMonth}-${startDay}`;
  const mstartDateISO = startDate[0]?.split("-").reverse().join("-");
  const endDateISO = moment(endDate[0], "DD-MM-YYYY").format("YYYY-MM-DD");
  const todaydate = formattedDate?.split("-").reverse().join("-");

  const newEndDate = new Date(endDateISO);
  newEndDate.setDate(newEndDate.getDate());

  const updatedEndDateISO = "";
  // const updatedEndDateISO = newEndDate?.toISOString()?.split("T")[0];
  useEffect(() => {
    const storedTableData = sessionStorage.getItem("tableData");
    if (storedTableData) {
      setdateDeatils(JSON.parse(sessionStorage.getItem("dateDeatils")));
      setformPopup(true);
    }
  }, []);

  useEffect(() => {
    if (startDate) {
      if (
        moment(startDate[0], "DD-MM-YYYY").format("YYYY-MM-DD") >
        moment(formattedDate, "DD-MM-YYYY").format("YYYY-MM-DD")
      ) {
        setRealstart(moment(startDate[0], "DD-MM-YYYY").format("YYYY-MM-DD"));
      } else {
        if (
          moment(endDate[0], "DD-MM-YYYY").format("YYYY-MM-DD") <
          moment(formattedDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        ) {
          setIsEvent(true);
        }
        setRealstart(moment(formattedDate, "DD-MM-YYYY").format("YYYY-MM-DD"));
      }
      // else {
      //   setIsEvent(true)
      // }
    }
  }, [startDate]);

  return (
    <>
      {formPopupLoading ? (
        <div
          style={{
            position: "absolute",
            zIndex: "500",
            top: "0px",
            right: "0px",
            width: "100%",
          }}
        >
          <div className="loadingMainSection" style={{ height: "100vh" }}>
            <div className="loader"></div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="bookingConatiner">
        <div className="bannerConatiner">
          <img src={banner} alt="pic" />
          <div className="bannerText">
            <h3>Booking</h3>
          </div>
        </div>
        <div className="bookingSections">
          <div className="bookingDetails">
            {getactivesubpackagesdata
              ?.filter((sbpck) => sbpck._id === id)
              .map((subpackages) => (
                <div className="row listdata" key={subpackages._id}>
                  <div className="col-md-12">
                    <h2 className="titlepack">{subpackages.pkg_name}</h2>
                  </div>

                  <div className="col-md-6">
                    <h4>
                      Min Person Allowed:{" "}
                      <span>{subpackages.min_person_allowed}</span>{" "}
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h4>
                      Max Person Allowed:{" "}
                      <span>{subpackages.max_person_allowed}</span>{" "}
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h4>
                      Reporting Time:
                      <span>{subpackages.reporting_time}</span>{" "}
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h4>
                      Reporting Place:{" "}
                      <span>{subpackages.reporting_place}</span>{" "}
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h4>
                      Package Duration: <span>{subpackages.time} days</span>{" "}
                    </h4>
                  </div>
                  {/* {subpackages.foreign_max_person_allowed &&
                  subpackages.foreign_min_person_allowed ? (
                    <>
                      <div className='col-md-6'>
                        <h4>
                          Foreign Max Person Allowed:
                          <span>
                            {subpackages.foreign_max_person_allowed}
                          </span>{' '}
                        </h4>
                      </div>
                      <div className='col-md-6'>
                        <h4>
                          Foreign Min Person Allowed:{' '}
                          <span>{subpackages.foreign_min_person_allowed}</span>{' '}
                        </h4>
                      </div>
                    </>
                  ) : null} */}

                  <table class="table my-5">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Adult</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Indian</th>
                        {subpackages.per_person_flag ? (
                          <td>{subpackages.indian_adult} INR / Per Person</td>
                        ) : (
                          <td>{subpackages.indian_adult} INR / Per Group</td>
                        )}
                      </tr>
                      {subpackages.foreign_adult !== "0" ? (
                        <tr>
                          <th scope="row">Foreign</th>
                          {subpackages.per_person_flag ? (
                            <td>{subpackages.foreign_adult} / Per Person</td>
                          ) : (
                            <td>{subpackages.foreign_adult} / Per Group</td>
                          )}
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                  <p
                    className="descriptionText mb-3"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(subpackages.description),
                    }}
                  />
                  {/* {subpackages.foreign_max_person_allowed &&
                  subpackages.foreign_min_person_allowed ? (
                    <>
                      <p>
                        Foreign Max Person Allowed:{" "}
                        {subpackages.foreign_max_person_allowed}
                      </p>
                      <p>
                        Foreign Min Person Allowed:{" "}
                        {subpackages.foreign_min_person_allowed}
                      </p>
                    </>
                  ) : null} */}

                  <Bookignform
                    getAvailableSlotdata={getAvailableSlotdata}
                    setgetAvailableSlot={setgetAvailableSlot}
                    formPopup={formPopup}
                    id={id}
                    setformPopup={setformPopup}
                    getactivesubpackagesdata={getactivesubpackagesdata}
                    dateDeatils={dateDeatils}
                    per_person_flag={subpackages.per_person_flag}
                    MaxPersonAllowed={subpackages.max_person_allowed}
                    MinPersonAllowed={subpackages.min_person_allowed}
                    ForeignMaxPersonAllowed={subpackages.max_person_allowed}
                    ForeignMinPersonAllowed={subpackages.min_person_allowed}
                    loginPopup={loginPopup}
                    setloginPopup={setloginPopup}
                    setbookingSummaryId={setbookingSummaryId}
                  />
                </div>
              ))}
          </div>
          <div className="bookingAvlCal">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              initialDate={startDateISO}
              validRange={{
                start: realstart,
                end: endDateISO,
              }}
              fixedWeekCount={false}
              aspectRatio={1.4}
              showNonCurrentDates={false}
              eventClick={(info) => BookingDateSubmit(info)}
              eventBackgroundColor="red"
              events={[
                {
                  title: "Available",
                  startTime: "12:00:00",
                  color: "green",
                  endTime: "12:00:00",
                  daysOfWeek: [
                    monday[0] ? 1 : ``,
                    tuesday[0] ? 2 : ``,
                    wednesday[0] ? 3 : ``,
                    thursday[0] ? 4 : ``,
                    friday[0] ? 5 : ``,
                    saturday[0] ? 6 : ``,
                    sunday[0] ? 0 : ``,
                  ],
                  display: isEvent ? "none" : "",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
